
/**
 * Test
 */
.quiz{
	position: relative;
	overflow: hidden;
	height: 100vh;

	.logo-loop path{
		opacity: 1;
	}
}

.question{
	position: relative;
	z-index: 1;
	height: 100vh;
	align-items: center;
	justify-content: center;
	display: flex;
	text-align: center;
	opacity: 1;
	transition: .5s;

	.inner{
		width: 90%;
		max-width: $contentWidth;
		transform: translateY(-15%);
		position: relative;
		z-index: 1;
	}

	.title-xl{
		margin-bottom: $gutter * 2;
	}

	@include below($l){
		.inner{
			max-width: 800px;
			transform: translateY(0%);
		}
	}

	@include below($m){
		align-items: start;

		.inner{
			max-width: 800px;

			transform: translateY(10%);
		}
	}

}

.quiz-category{
	opacity: .7;
	margin-bottom: 30px;

	@include below($s){
		opacity: .5;
		margin-bottom: 10px;
		font-size: 16px;
	}
}

.quiz-front{
	background: #000;
	position: relative;

	.logo-loop{
		opacity: .6;
	}

	&:after{
		content: '';
		opacity: .8;
		position: absolute;
		top:0;
		left: 0;
		right: 0;
		bottom: 0;
		background-image: url('../img/test-background.jpg');
		background-size: cover;
	}
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.fade-enter-active {
  transition: all .3s ease;
}
.fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.progress-bar{
	position: absolute;
	z-index: 2;
	bottom: 80px;
	width: 620px;
	left: 50%;
	transform: translateX(-50%);
	height: 2px;
	background: $blue;
	box-shadow: 0 0 0 1px #FFFFFF;

	.progress{
		position: relative;
		background: $blue;
		width: 0%;
		height: 14px;
		border-radius: 30px;
		top: -6px;
		transition: .5s;
		box-shadow: 0 0 0 1px rgba(255,255,255,.7);
	}

	@include below($s) {
		width: 80%;
	}
}
.quiz-nav-prev{
	left: 0;
	transform: translateX(-150%);

}
.quiz-nav-next{
	right: 0;
	transform: translateX(150%);
}

.quiz-nav-prev,
.quiz-nav-next{
	position: absolute;
	top: -12px;
	text-decoration: none;
	color: $black;

	@include below($s) {
		top: 0;
		transform: translate(0, 20px);
	}
}


.slide-fade-enter-active {
	transform: scale(.8);
}
.slide-fade-leave-active {
  opacity: 1;
}
.slide-fade-enter{
  transform: translateX(0px);
  opacity: 0;
}
.slide-fade-leave-to{
  transform: scale(.8);
  opacity: 0;
}

@include below($s){

	.question .button{
		padding: 8px 20px;
	}
}