.result-intro{
	display: bock;
	margin: 0 auto;
	max-width: $contentWidth;
	padding: 0 $gutter;

	@include below($s) {

		.content-article {
			padding-bottom: 1.5*$contentPadding;
		}
	}
}

// Result table
.result-table{
	position: relative;
	z-index: 1;
	align-items: flex-end;
	align-content: flex-end;
	margin: 0 auto;
	width: $contentWidth;
	display: flex;
	height: 400px;
	text-align: center;

	.staple{
		display: flex;
		align-items: flex-end;
		align-content: flex-end;
		margin: 0 10px;
		width: 140px;
		height: 100%;
		position: relative;


		&:after{
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 80px;
			height: 40%;
			background-image: url('../img/pattern.png');
			border-top-left-radius: 100px;
			border-top-right-radius: 100px;
			opacity: .4;
		}
	}

	.result{
		position: relative;
		border-top-left-radius: 100px;
		border-top-right-radius: 100px;
		height: 0px;
		max-height: 100%;
		min-height: 30%;
		bottom: 0px;
		width: 80px;
		margin: 0 auto;
	}

	span{
		position: absolute;
	    top: -50px;
	    width: 114px;
	    display: inline-block;

	}
}

@include below($m) {

	.page-template-page-result .inner {
		min-height: 770px;
	}

	.result-table {
	    width: 100%;
	    height: 100vw;
	    transform: rotate(90deg);

		span {
			top: auto;
			transform: rotate(-90deg);
			bottom: 100px;
			height: 1px;
			text-align: left;
		    width: 200px;
		    bottom: 120px;
		    left: -55px;
		}

		.staple {
			width: 40px;
			margin-right: 1.5*$contentPadding;

			&:after {
				width: 40px;
			}
		}
	}
}