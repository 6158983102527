

// Types
.title-xl{
	font-family: filson-soft;
	font-size: 90px;
	line-height: 110px;
	font-weight: 500;

	@include below($l) {
		font-size: 70px;
		line-height: 80px;
	}

	@include below($s) {
		font-size: 40px;
		line-height: 50px;
	}
}
.title-l{
	font-family: filson-soft;
	font-size: 80px;
	line-height: 90px;
	margin: $gutter 0;
	font-weight: 500;

	@include below($l) {
		font-size: 60px;
		line-height: 70px;
	}

	@include below($s) {
		font-size: 32px;
		line-height: 38px;
	}
}
.title-m{
	font-size: 48px;
	line-height: 60px;
	font-weight: 500;
	margin: $gutter 0;

	@include below($l) {
		font-size: 38px;
		line-height: 48px;
	}

	@include below($s) {
		font-size: 24px;
		line-height: 30px;
	}
}

.title-s{
	font-weight: 500;
	font-size: 24px;
	line-height: 33px;
	margin-top: $gutter;
	margin-bottom: $gutter;

	@include below($s) {
		font-size: 18px;
		line-height: 24px;
	}
}


p,
.paragraph{
	font-size: 18px;
	line-height: 24px;
	font-weight: 300;
	margin: 10px 0;

	@include below($s) {
		font-size: 16px;
		line-height: 22px;
	}

	a{
		color: inherit;
		text-decoration: none;
		font-weight: 500;
	}
}
.title-xl{}


.align-left{
	text-align: left;
}
.align-right{
	text-align: right;
}
.align-center{
	text-align: center;
}

.uppercase{
	text-transform: uppercase;
}

.link{
	color: #000;
	text-decoration: underline;

	&.color-white{
		color: #fff;
	}

}