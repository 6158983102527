.button{
	font-size: 18px;
	background: $pink;
	display: inline-block;
	padding: 12px 40px 13px;
	color: #fff;
	text-decoration: none;
	border-radius: 40px;
	margin: $gutter 0;
	transition: .15s;
	box-shadow: 5px 5px 0px rgba(0,0,0,.1);
	cursor: pointer;
	font-weight: bold;

	&:hover{
		box-shadow: 2px 2px 0px rgba(0,0,0,.1);
		background: darken($pink, 3%);
	}
	&:active{
		background: darken($pink, 5%);
		box-shadow: 0px 0px 0px rgba(0,0,0,.1);
	}

	@include below($s) {
		font-size: 15px;


	}

	&.bg-white{
		&:hover{
			color: #fff;
			background: $blue;
		}

		&.is-active{
			color: #fff;
			background: $blue;
		}
		&:active{
			color: #fff;
			background: darken($blue, 10%);
		}

	}
	&.bg-pink{
		&:hover{
			background: darken($pink, 3%);
		}
		&:active{
			color: #fff;
			background: darken($pink, 8%);
		}
	}
	&.bg-blue{

		&:hover{
			background: darken($blue, 3%);
		}

		&:active{
			background: darken($blue, 8%);
		}

	}

	&.bg-white{
		color: $blue;

		&:visited{
			color: $blue;
		}

		&:hover{
			color: $blue;
			background: darken(#fff, 1%);
		}

		&:active{
			color: $blue;
			background: darken(#fff, 4%);
		}

	}

}

.buttons{
	display: block;
	list-style: none;
	margin: ($gutter * 2) 0;

	li{
		margin: 0 10px;
		display: inline-block;
	}

	@include below($l){
		li{
			margin-left: 5px;
			margin-right: 5px;
		}
	}

	@include below($s) {
		margin: 10px 0;

		li{
			width: 70%;
			margin: 0;
			display: inline-block;
		}

		.button{
			margin: 5px 0;


		}
	}
}

.button-radio{
	width: 120px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	cursor: pointer;

	&.is-active{
		.radio:after{
			box-shadow: inset 0 0 0 20px $blue;
		}
	}

	span{
		display: block;
		color: $black;
		font-size: 18px;
	}

	.radio {
		position: relative;
		border-radius: 100px;
		display: inline-block;
		width: 46px;
		height: 46px;
		background: #fff;
		margin-bottom: 20px;
		transition: .2s;
		box-shadow: 5px 5px 0px rgba(0,0,0,.1);

		&:after{
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: block;
			border-radius: 100px;
			width: 74%;
			height: 74%;
			box-shadow: inset 0 0 0 2px $blue;
			transition: .2s;
		}

	}

	@include above($s){
		&:hover{
			.radio:after{
				opacity: .7;
				box-shadow: inset 0 0 0 20px $blue;
				transition: .2s;
			}
		}
		&:active{

			.radio{
				box-shadow: 4px 4px 0px rgba(0,0,0,.2);
			}
			.radio:after{
				opacity: 1;
				box-shadow: inset 0 0 0 20px $blue;
				transition: .2s;
			}

		}
	}

	@include below($s) {
		display: block;
		width: 100%;
		padding: 5px 0px;
		text-align: left;


		.radio{
			width: 35px;
			height: 35px;
			margin-bottom: 0;
		}

		span{
			position: relative;
			display: inline-block;
			vertical-align: top;
		}

		.label{
			margin-left: 20px;
			top: 10px;
		}
	}
}



.logo-loop {
	position: absolute;
	bottom: 0;
	right: 0;
	pointer-events: none;



	svg{
		width: 4000px;
	}

	path{
		fill: #fff;
		opacity: .3;
		transition: 1s;
	}

	transform: rotate(0) translateZ(0);
	animation-name: snake;
    animation-duration: 50s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @include below($s){
    	svg{
    		width: 1400px;
    	}
    	right: 00%;
	}
}


.logo-loop-small{
	position: absolute;
	top: 80px;
    width: 200%;
    transform: rotate(-190deg);

	svg{
		width: 100%;
		height: auto;
	}
}

/* The animation code */
@keyframes snake {
    100% {
    	transform: rotate(360deg)  translateZ(0);
    }
}


.color{
	&-white{
		color: #fff;
	}
	&-black{
		color: $black;
	}
	&-yellow{
		color: $yellow;
	}
	&-light-yellow{
		color: $lightYellow;
	}
	&-lighter-yellow{
		color: $lighterYellow;
	}
	&-orange{
		color: $orange;
	}
	&-blue{
		color: $blue;
	}
	&-deep-blue{
		color: $deepBlue;
	}
}

.bg{

	&-black{
		background: $black;
	}

	&-white{
		background: #fff;
	}

	&-balans,
	&-orange{
		background: $orange;
	}

	&-objektskontroll,
	&-blue{
		background: $blue;
	}

	&-koordination,
	&-deep-blue{
		background: $deepBlue;
	}

	&-hoppa-landa,
	&-pink{
		background: $pink;
	}

	&-ga-springa,
	&-yellow{
		background: $yellow;
	}

	&-light-yellow{
		background: $lightYellow;
	}
	&-lighter-yellow{
		background: $lighterYellow;
	}


	&-gallery{
		overflow: hidden;
		position: relative;
		background-position: center;
		background-size: cover;
	}

}

.fill{

	&-black path{
		fill: $black;
	}

	&-balans path,
	&-orange path{
		fill: $orange;
		opacity: 1;
	}

	&-objektskontroll path,
	&-blue path{
		fill: $blue;
		opacity: 1;
	}

	&-koordination path,
	&-deep-blue path{
		fill: $deepBlue;
		opacity: 1;
	}

	&-hoppa-landa path,
	&-pink path{
		fill: $pink;
		opacity: 1;
	}

	&-ga-springa path,
	&-yellow path{
		fill: $yellow;
		opacity: 1;
	}

	&-light-yellow path{
		fill: $lightYellow;
		opacity: 1;
	}
	&-lighter-yellow path{
		fill: $lighterYellow;
	}
}