.edit-exercise{
	position: absolute;
	color: $blue;
	background: #fff;
	padding: 8px 15px;
	text-decoration: none;
	z-index: 100;
}

.exercises-list{
	position: relative;
	overflow: hidden;
	display: flex;
	flex-align: center;
	padding: 100px 0;

	.holder{
		position: relative;
		z-index: 1;
		width: 100%;
		max-width: 140px * 6 + 40px;
	}

	.animation{
		top: 100px;
    	left: 50%;
    	transform: translateX(-900px);
    	position: absolute;
    	height: 100%;

    	svg{
    		width: 1000px !important;
    		height: auto !important;
    	}
	}

	@include below($l){
		padding: 150px 0;
	}

	@include below($m){
		padding: 20px 10px;
	}
}

.exercise-item{
	margin: 0 10px 10px;
	background: #fff;
	box-shadow: 10px 10px 0 rgba(0,0,0,.1);
	margin: 0 10px $gutter;
	transition: .2s width;

	&.active{
		width: 100%;

		iframe{
			opacity: 1;
			pointer-events: auto;
		}

		.button{
			opacity: 0;
		}

		&:hover{
			.button.close{
				opacity: 1;
			}
		}
	}

	.button.close{
		opacity: 0;
		top: 65px;
		right: 20px;
		left: auto;
		padding: 10px 15px;
		font-size: 14px;
		transform: translate(0);
		z-index: 5;

		@include below($s){
			font-size: 14px;
			width: 40px;
		}
	}

	.player{
		position: relative;
		width: 100%;
		padding-top: 56.25%;
		overflow: hidden;
		background: #000;
	}



	iframe{
		pointer-events: none;
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		bottom: 0;
		right: 0;
		left: 0;
		border: 0;
		opacity: 0;
		z-index: 2;
	}

	.button{
		z-index: 2;
		position: absolute;
		top: 50%;
		left: 50%;
		text-align: center;
		margin: 0;
		transform: translate(-50%,-50%);

		@include below($s){
			display: none;
		}
	}

	img{
		top: 0;
		left: 0;
		position: absolute;
		width: 100%;
		opacity: 1;
		display: block;
	}

	.requirements{
		margin: 20px 0;
		list-style: none;
		font-size: 18px;
		line-height: 24px;

		span{
			font-weight: 500;
			color: $blue;
		}
	}

	.content{
		text-align: left;
		padding: 10px 50px 40px;
	}
	@include below($s) {
		padding: 0;

		.requirements{
			margin: 10px 0;

			li{
				font-size: 16px;
			}
		}
		.content{
			text-align: left;
			padding: 10px 20px 10px;
		}
	}
}

//
// SCROLLL ITEM
//
@include below($s){

	.exercises-list{
		display: block;

	    .holder{
	    	display: flex;
	    	flex-flow: nowrap;
	    	padding-right: 20px;
	    	overflow-x: scroll;
	    	-webkit-overflow-scrolling: touch;
	    	width: 100%;
	    }

		 .exercise-item{
		 	margin-right: 10px;
		 }

	    .exercise-item .player{
	    	width: 270px;
	    }
	}
}