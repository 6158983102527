.item-slider{
	padding: 90px 0;
}
.swiper-container{
	width: 100%;
	margin: 0 auto;
	padding: 0 ;
}

.swiper-slide{
	max-width: 320px;
	text-align: center;
}


.slide-item{
	background: #fff;
	//display: inline-block;
	//background: #fff;
	//max-width: 460px;
	box-shadow: 10px 10px 0 rgba(0,0,0,.1);
	margin: 0 10px 10px;

	img{
		width: 100%;
	}

	.requirements{
		margin: 20px 0;
		list-style: none;
		font-size: 18px;
		line-height: 24px;

		span{
			color: $blue;
		}
	}

	.content{
		text-align: left;
		padding: 20px 50px 50px;
	}
}

.swiper-button-next,
.swiper-button-prev{
	background: none;
	background-color: #fff;
	background-image: url(../img/arrow-left.svg);
	background-repeat: no-repeat;
	background-position: center;
	width: 50px;
	height: 50px;
	border-radius: 100px;
	box-shadow: 5px 5px 0 rgba(0,0,0,.1);
	outline: none;

	&:hover{
		background-color: #F1F1F1;
	}
}

.swiper-button-prev{
	left: 40px;
}
.swiper-button-next{
	background-image: url(../img/arrow-right.svg);
	right: 40px;
}