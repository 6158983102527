#main-header{
	top: 0;
	left: 0;
	right: 0;
	padding: 40px 50px;
	position: absolute;
	z-index: 10;
	transition: .3s cubic-bezier(0, 1, 0.5, 1);

	@include below($s){
		padding: .5*$contentPadding;

		&.open {

			.main-logo {

				path{
					fill: $blue !important;
				}
			}

		    .main-nav {
				transform: translateY(0);	   
				box-shadow: 5px 5px 0px rgba(0,0,0,.1); 	
		    }

		    #mobile-nav {
		    	
		    	&:before {
		    		background-color: $blue !important;
			    	top: 16px;
			    }

			    &:after {
			    	background-color: $blue !important;
			    	bottom: 16px;
			    }
		    }
		}
	}
}

.main-logo {

	path{
		fill: $blue;
		transition: .3s cubic-bezier(0, 1, 0.5, 1);
	}

	@include below($s) {
	    z-index: 1;
	    position: relative;

		svg{
			width: 40px;
			height: auto;
		}
	}
}

.main-nav{
	position: absolute;
	right: 50px;
	top: 40px;
	color: #fff;

	a{
		font-weight: 500;
		color: $blue;
		font-size: 18px;
		margin: .5*$contentPadding;
		text-decoration: none;
	}

	@include below($s){
		width: 100vw;
		height: auto;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	    padding: 2.5*$contentPadding 0;
	    text-align: center;
	    background: white;
	    transition: .3s cubic-bezier(0, 1, 0.5, 1);
	    transform: translateY(-100%);

	    &.open {
			transform: translateY(0);	    	
	    }

	    a {
	    	display: block;
	    	margin: $contentPadding .5*$contentPadding;
	    	color: $blue !important;
	    }
	}
}

#mobile-nav {
    width: 35px;
    height: 35px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;

    &:before, &:after {
    	content: '';
    	display: block;
    	position: absolute;
    	width: 100%;
    	height: 3px;
    	background: $blue;
    	transition: .3s cubic-bezier(0, 1, 0.5, 1);
    }

    &:before {
    	top: 10px;
    }

    &:after {
    	bottom: 10px;
    }

    &.open {
    	
    	&:before {
	    	top: 16px;
	    }

	    &:after {
	    	bottom: 16px;
	    }
    }
}

.page-template-page-test{

	.main-logo path{
		fill: $yellow;
	}
	.main-nav a{
		color: #fff;
	}
	#mobile-nav:before,
	#mobile-nav:after {
		background-color: $yellow;
	}
}

.home{

	.main-logo path{
		fill: #fff;
	}
	.main-nav a{
		color: #fff;
	}
	#mobile-nav:before,
	#mobile-nav:after {
		background-color: #fff;
	}
}