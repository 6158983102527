@import 'normalize';
@import 'entypo';
@import 'breaker';
@import 'mixins';

// Gone is the desktop, table and mobile. Let's use s, m, l, xl.
$s: 500px;
$m: 800px;
$l: 1200px;
$xl: 1410px;

// Default content settings.
$contentMaxWidth: 1440px; //1440px;
$contentWidth: 940px;
$contentPadding: 20px;
$gutter: 20px;
//$contentMaxWidth: 90%;

// Font weights.
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

// Colors
$orange: #FFA558;
$blue: #2BC9D6;
$deepBlue: #795CF2;
$yellow: #F7D134;
$lightYellow: #FEE46B;
$lighterYellow: #FBF8E8;
$pink: #EC0049;
$black: #313131;
$white: #fff;

@import 'lib/swiper';
@import 'grid';
@import 'elements';
@import 'types';
@import 'global';
@import 'mainHeader';
@import 'slider';
@import 'quiz';
@import 'results';


// Hero
.hero{
	position: relative;
	background-size: cover;
	background-position: center;
	height: 80vh;
	display: flex;
	align-items: center;
	overflow: hidden;

	.inner{
		position: relative;
		z-index: 1;
		width: 100%;
		max-width: 1000px;
		margin: 0 auto;
	}

	@include below($s){
		.inner{

			padding: 0 10px;
		}
	}
}


// Manifest
.manifest{
	overflow: hidden;
	position: relative;
	text-align: center;
	padding-top: 200px;
	padding-bottom: 200px;

	.inner{
		width: 90%;
		max-width: 880px;
		margin: 0 auto;
	}

	p{
		max-width: 876px;
	}
	@include below($s){
		padding: 50px 0;

		p{
			font-size: 18px;
			line-height: 28px;
		}
	}
}

.content-article{
	padding-top: 120px;
	padding-bottom: 120px;

	&-narrow{
		padding-top: 80px;
		padding-bottom: 80px;
	}

	@include below($l){
		padding-top: 50px;
		padding-bottom: 50px;
	}
	@include below($s){
		text-align: center;

		&-narrow{
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}
}


.who-is-behind{
	position: relative;

	h3{
		margin-bottom: $gutter;
	}

	img{
		width: 100px;
		display: block;
		margin: $gutter 0;
	}

	.logos{
		position: absolute;
		top: 50%;
		right: $gutter * -2;
		transform: translate(100%, -50%)
	}

	@include below($l) {
		.logos{
			display: block;
			position: relative;;
			transform: translate(0, 0);
			width: 100%;
			margin-top: 30px;
			right: auto;
		}
		img{
			margin: 0 20px;
			display: inline-block;
		}
	}

	@include below($s) {

		.logos {
			text-align: center;
		}
	}
}

.main-footer{
	padding: 5*$contentPadding 0;

	.link{
		font-size: 18px;
	}

	.logo{
		margin-bottom: $contentPadding;
	}

	@include below($s) {
		padding: 3*$contentPadding 0;

		.logo {
			text-align: center;
			margin-bottom: 0;
			margin-top: 2*$contentPadding;
		}
	}
}


.archive-header{
	position: relative;
	padding-top: 60px;
	padding-bottom: 60px;
	box-shadow: 0 10px 0 rgba(0,0,0,.1);
	z-index: 1;

	@include below($s){
		padding: $contentPadding;
	}
}

.animation{
	top: 0;
	right: 0;
	left: auto;
	position: absolute;
}

.category-box{
	position: relative;
	padding-top: 200px;
	padding-bottom: 50px;
	overflow: hidden;

	article{
		position: relative;
		z-index: 1;
	}
	.animation{
		bottom: 100px;
		right: 0;
		left: auto;
		position: absolute;
	}

	&:nth-child(even){
		.animation{
			right: auto;
			left: 0;
		}
	}

	.animation{
		position: absolute;

		svg{
			width: auto !important;
			height: 400px !important;
		}
	}

	@include below($s){
		padding-top: 50px;
		padding-bottom: 50px;
		text-align: center;;
	}
}

.snake-holder{
	position: relative;
	overflow: hidden;

	.inner{
		position: relative;
		z-index: 1;
	}
}


@import 'exerciselist';

.dropdown-menu{
	margin-top: $gutter;


	ul{
		list-style: none;
	}

	li{
		a{
			color: #000;
			text-decoration: none;
		}
	}

	@include below($s){
		margin-top: 0;
	}

}

.only-b-s {
	display: none;
}

@include below($m) {

	.not-b-m {
		display: none;
	}
}

@include below($s) {

	.not-b-s {
		display: none;
	}

	.only-b-s {
		display: block;
	}
}

input{
	outline: none;
	color: $blue;
	margin: 0 5px;
	border: 0;
	border-radius: 50px;
	padding: 13px 30px;
	width: 250px;
	max-width: 100%;
	box-shadow: 5px 5px 0 0 rgba(0,0,0,0.10);
}