.holder{
	max-width: $contentMaxWidth;
	margin: 0 auto;
}

// Grid
$baseGridWidth: 140px;

.col{
	margin-left: $gutter / 2;
	margin-right: $gutter / 2;

	&-1 { width: $baseGridWidth; }
	&-2 { width: $baseGridWidth * 2; }
	&-3 { width: $baseGridWidth * 3; }
	&-4 { width: $baseGridWidth * 4; }
	&-5 { width: $baseGridWidth * 5; }
	&-6 { width: $baseGridWidth * 6; }

	@include below($s){
		margin-left: 0;
		margin-right: 0;
		&-1,
		&-2,
		&-3,
		&-4,
		&-5,
		&-6 {
			width: 100%;
			padding-left: $gutter;
			padding-right: $gutter;
		}
	}
}

.responsive-col{
	&-1 {
		display: block;
		margin: 0 auto;
		max-width: $baseGridWidth * 6;
		width: 100%;
	}
	&-2 { width: 50%; }
	&-3 { width: 33.333%; }
	&-4 { width: 25%; }
	&-5 { width: 20%; }
	&-6 { width: 16.666%; }

	@include below($s) {
		&-1,
		&-2,
		&-3,
		&-4,
		&-5,
		&-6 {
			width: 100%;
		}
	}

}

.flex{
	display: flex;
	flex-flow: row wrap;
}

.flex-center{
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}
.flex-fill{
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
}

.flex-start{
	justify-content: flex-start !important;
}
.flex-end{
	justify-content: flex-end !important;
}
.flex-align-items-start{
	align-items: flex-start !important;
}
.full-width{
	width: 100%;
}

[class*="responsive-col-"]{
	display: flex;
	align-items: center;
}

@include above($s) {

	[class*="responsive-col-"]:nth-child(odd){
		justify-content: flex-end;

		[class*="col-"]{
			padding-right: $gutter * 3;
		}
	}

	[class*="responsive-col-"]:nth-child(even){
		justify-content: flex-start;

		[class*="col-"]{
			padding-left: $gutter * 3;
		}
	}
}